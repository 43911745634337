import { render, staticRenderFns } from "./PlanStep5.vue?vue&type=template&id=467e0546&scoped=true"
import script from "./PlanStep5.vue?vue&type=script&lang=js"
export * from "./PlanStep5.vue?vue&type=script&lang=js"
import style0 from "./PlanStep5.vue?vue&type=style&index=0&id=467e0546&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467e0546",
  null
  
)

export default component.exports